
import { ref, defineComponent, onMounted, onUnmounted } from 'vue'
import Tooltip from '@/components/UI/Tooltip.vue'
export default defineComponent({
  emits: ['onCheck'],
  props: {
    item: {
      type: Object,
      default: () => ({} || '')
    },
    brand: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: ''
    },
    isBrands: {
      type: Boolean,
      default: false
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    isFilter: {
      type: Boolean,
      default: false
    },
    forEmployee: {
      type: Boolean,
      default: false
    },
    forCarBrands: {
      type: Boolean,
      default: false
    },
    forHierarchyRow: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Tooltip
  },
  setup(props, { emit }) {
    const el = ref<HTMLDivElement>(document.createElement('div'))
    const moreInfo = ref(false)
    const activeOption = ref(false)
    const onCheck = (item, $event) => {
      if (!props.isBrands) {
        const checkBoxActive = item
        if ($event.target.checked) {
          checkBoxActive.isActive = true
        } else {
          checkBoxActive.isActive = false
        }
      }
      emit('onCheck', $event)
    }
    const closeMoreInfo = (e) => {
      const target = e.target
      if (el.value && el.value !== target && !el.value.contains(target)) {
        moreInfo.value = false
      }
    }
    onMounted(() => {
      document.addEventListener('click', closeMoreInfo)
    })
    onUnmounted(() => {
      document.removeEventListener('click', closeMoreInfo)
    })
    return { moreInfo, activeOption, onCheck, closeMoreInfo, el }
  }
})
